<template>
    <div class="model-config-container" style="padding:10px; border:1px solid var(--light-purple)">

        <p class="mb-0">Choose Instance</p>
        <a-select v-model="modelSettings.instance" :options="instances" />
        <template v-if="modelSettings.instance">
            <p class="mb-0">Specs to show</p>
            <a-select mode="multiple" v-model="modelSettings.specs" style="width: 100%" placeholder="Price, Sqft, Beds, etc..." :options="fields" />
            
            <template v-if="tagsOptions.length">
                <p class="mt-1 mb-0">Tags to Show</p>
                <a-select mode="multiple" v-model="modelSettings.tags" style="width: 100%" placeholder="Choose tags to filter by" :options="tagsOptions" />
            </template>
        </template>

    </div>

</template>

<script>
export default {
    props:{
        value:{
            type:Object,
            required:true,
            default: () => {}
        },
    },
    data(){
        return {
            isPicker:false,
            initialColor:'#000',
            colorPickerTarget:['modelSettings','titleColor'],
            fields:[
                {
                    label:'Bedroom',
                    value:'beds',
                },
                {
                    label:'Bathroom',
                    value:'baths',
                },
                {
                    label:'Garage',
                    value:'garage',
                },
                {
                    label:'Price',
                    value:'price',
                },
                {
                    label:'Sqft',
                    value:'sqft',
                },
            ],
            modelSettings:{
                instance:'',
                column:2,
                specs:[],
                tags:[]
            },
        }
    },
    computed:{
        instances(){
            let {children = [],...instance} = this.$store.state.instance
            let options =  [instance,...children]
            return options.map( ({id,name}) => ({
                label:name,
                value:id
            }))
        },
        tags(){
            return this.$store.state.appData.modelTags
        },
        tagsOptions(){
            let tags = this.tags[this.modelSettings.instance]
            if (!tags) tags = []
            return tags.map( ({name,id}) => ({
                label:name,
                value:id
            }))
        }
    },
    watch:{
        modelSettings:{
            deep:true,
            async handler(val,oldVal){
                if (val.instance){
                    if (!this.tags[val.instancej]){
                        let {data} = await this.$api.get(`/tags/${val.instance}?type=models`)
                        this.$store.commit('SET_PROP', {where:['modelTags',val.instance],what:data})
                    }
                }
                return this.$emit('input', val)

            }
        }
    },
    async created(){
        this.modelSettings = {
            ...this.modelSettings,
            ...this.value
        }
        

    },
}
</script>

<style scoped lang="scss">
    .button-editor-container{
        padding:10px;
        border:1px solid #eee;
        
    }
</style>